import { inject, Injectable } from '@angular/core';
import { UserStoreService } from '@shared/services/user-store.service';
import { ThemeService } from '@shared/services/theme.service';
import { AppService } from '@shared/services/app.service';
import { DeviceInfoService } from '@shared/services/device-info.service';
import { AppStylesService } from '@shared/services/app-styles.service';
import { fetchAuthSession } from 'aws-amplify/auth';
import { AppStorageService } from '@shared/services/app-storage.service';
import { MockDataService } from '@shared/services/mock-data.service';
import { UsersHttpService } from '@core/http/users-http.service';

@Injectable({
    providedIn: 'root',
})
export class AppStarterService {
    private usersHttpService = inject(UsersHttpService);
    private userStoreService: UserStoreService = inject(UserStoreService);
    private appService: AppService = inject(AppService);
    private themeService: ThemeService = inject(ThemeService);
    private deviceInfoService: DeviceInfoService = inject(DeviceInfoService);
    private appStylesService: AppStylesService = inject(AppStylesService);
    private appStorageService = inject(AppStorageService);
    private mockDataService = inject(MockDataService);

    async initServices(): Promise<void> {
        this.themeService.fnInit();
        this.appStylesService.fnInit();
        this.mockDataService.createDb();
        await this.deviceInfoService.fnInit();
        if (this.deviceInfoService.isTablet()) this.appService.closeMenu();
    }

    // redirect: used after login form -> redirect to main path if no path saved
    initUser(redirect: boolean = false): Promise<boolean> {
        if (!redirect) this.appService.startLoading();

        return new Promise(resolve => {
            const initializeUser = () => {
                this.usersHttpService.getUserData().subscribe(response => {
                    if (response.value) {
                        this.userStoreService.updateUser({
                            ...response.value,
                            isLoggedIn: !redirect,
                            allowLanding: true,
                        });
                        resolve(response.value.hasRole);
                    }

                    if (response.error) resolve(false);

                    this.appService.stopLoading();
                });
            };

            if (!redirect) {
                fetchAuthSession()
                    .then(session => {
                        const token = session.tokens?.accessToken.toString();

                        if (!token) {
                            this.userStoreService.clearUser();
                            this.appService.stopLoading();
                            resolve(false);
                        } else {
                            const isFromSSO: boolean =
                                this.appStorageService.getItem(this.appService.STORAGE_SSO) ??
                                false;
                            if (isFromSSO) redirect = true;
                            initializeUser();
                        }
                    })
                    .catch(error => {
                        this.userStoreService.clearUser();
                        this.appService.stopLoading();
                        resolve(false);
                    });
            } else {
                initializeUser();
            }
        });
    }
}
