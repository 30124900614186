import { Injectable } from '@angular/core';
import {
    IRouteAccessConfig,
    TChildrenPageType,
    TPageType,
    TRouteAccess,
} from '@shared/interfaces/route.interface';
import { translations } from '@shared/utils/translations';
import { UserGuard } from '@core/guards/user.guard';
import { AuthGuard } from '@core/guards/auth.guard';
import { TUserType } from '@shared/interfaces/user-data-role.interface';
import { LoginGuard } from '@core/guards/login.guard';
import { UserModel } from '@shared/models/user.model';

@Injectable({
    providedIn: 'root',
})
export class AppRouteService {
    get routeAccess(): TRouteAccess {
        return {
            auth: {
                canActivate: [LoginGuard],
                userTypes: [],
                routeConfig: {
                    path: 'auth',
                },
                icon: 'user',
            },
            users: {
                canActivate: [AuthGuard, UserGuard],
                userTypes: ['ADMIN', 'USER'],
                routeConfig: {
                    path: 'users',
                },
                title: translations.users.title,
                icon: 'users',
                visibleOnMenu: true,
            },
            properties: {
                canActivate: [AuthGuard, UserGuard],
                userTypes: ['ADMIN', 'USER'],
                routeConfig: {
                    path: 'properties',
                },
                title: translations.properties.title,
                icon: 'domain',
                visibleOnMenu: true,
            },
            screens: {
                canActivate: [AuthGuard, UserGuard],
                userTypes: ['ADMIN', 'USER'],
                routeConfig: {
                    path: 'screens',
                },
                title: translations.screens.title,
                icon: 'video_label',
                visibleOnMenu: true,
            },
        };
    }

    get defaultRoute(): TPageType {
        return 'users';
    }

    getMenuRoutes(): string[] {
        const getChildrenPaths = (page: TPageType): string[] => {
            const children: any = this.routeAccess[page]?.routeConfig.children ?? {};
            return Object.keys(children)
                .filter(child => children[child].mainRoute)
                .map(child => this.getPath(page) + '/' + child);
        };

        const parentRoutes = Object.keys(this.routeAccess)
            .filter((page: string) => this.routeAccess[page as TPageType].visibleOnMenu)
            .map((page: string) => this.getPath(page as TPageType));

        return parentRoutes.concat(
            ...Object.keys(this.routeAccess).map(page => getChildrenPaths(page as TPageType)),
        );
    }

    getRouteAvailability<T extends TPageType>(
        config: IRouteAccessConfig<T>,
        user: UserModel,
    ): boolean {
        const userType: TUserType | undefined = user.role;

        // skip route not visible
        if (!config.visibleOnMenu) return false;

        // default/general routes always should be shown if route config has as router type
        if (config.userTypes.some((type: string) => type == userType)) return true;

        return false;
    }

    getPath(mainPath: TPageType): string {
        return '/' + mainPath;
    }

    getTitle<T extends TPageType>(
        page: T,
        child?: TChildrenPageType[T] extends string ? TChildrenPageType[T] : never,
    ): string {
        const children = this.routeAccess[page].routeConfig.children as Record<
            string,
            { title?: string }
        >;
        if (child && children && typeof children[child] === 'object') {
            return children[child].title ?? '';
        }
        return this.routeAccess[page].title ?? '';
    }

    canActivate(mainPath: TPageType, url: any, user: UserModel): boolean {
        const userType: TUserType = user.role;
        const mainRoute: IRouteAccessConfig<any> = this.routeAccess[mainPath];

        // Check if the user type is allowed on the main route
        const canAccessMainRoute: boolean = mainRoute.userTypes.some(type => type === userType);

        if (!canAccessMainRoute) return false;

        // Directly return true if it's an exact match
        if (url === this.getPath(mainPath)) return true;

        // If the main route does not have any children, the user can access it
        if (!mainRoute.routeConfig.children) return true;

        // Check each child route to see if it matches the given URL
        for (const key in mainRoute.routeConfig.children) {
            const childRoute = mainRoute.routeConfig.children[key];
            if (this.matchUrl(mainPath, childRoute.path, url)) {
                // If the child route does not specify user types, inherit access from the main route
                if (!childRoute.userTypes) {
                    return true;
                }
                // Check if the user type is allowed on the child route
                return childRoute.userTypes.some(type => type === userType);
            }
        }

        // If no child routes match the URL, the user cannot access it
        return false;
    }

    private matchUrl(mainPath: TPageType, path: string, url: string) {
        const patternedPath: string = this.getPath(mainPath) + '/' + path;
        // Check if the path uses a generic parameter pattern (e.g., /:anything)
        if (path.includes(':')) {
            // Create a regex pattern to match parameterized paths, capturing segments after '/:'
            const regexPattern = patternedPath.replace(/\/:([^/]+)/g, '/([^/]+)') + '.*';

            const regex = new RegExp('^' + regexPattern + '$');

            return regex.test(url);
        } else {
            const baseUrl = url.split('?')[0];
            return patternedPath === baseUrl;
        }
    }
}
