import { Injectable } from '@angular/core';
import me from '@assets/mocks/me.json';
import users from '@assets/mocks/users.json';
import properties from '@assets/mocks/properties.json';

@Injectable({
    providedIn: 'root',
})
export class MockDataService {
    dynamicRoutes: any = {};

    createDb() {
        this.dynamicRoutes[`/api/me`] = me;
        this.dynamicRoutes[`/api/users`] = users;
        this.dynamicRoutes[`/api/properties`] = properties;
    }

    get(reqInfo: any) {
        if (['assets', 'icons'].includes(reqInfo.url)) return undefined;
        const url = this.normalizeUrl(reqInfo.url);
        return this.dynamicRoutes[url];
    }

    post(reqInfo: any) {
        const url = this.normalizeUrl(reqInfo.url);
        const body = reqInfo.body;

        if (this.dynamicRoutes[url] && Array.isArray(this.dynamicRoutes[url])) {
            const newItem = { ...body, id: this.generateId() };
            this.dynamicRoutes[url].push(newItem);
            return newItem;
        }
        return undefined;
    }

    put(reqInfo: any) {
        const url = this.normalizeUrl(reqInfo.url);
        const body = reqInfo.body;

        if (this.dynamicRoutes[url] && Array.isArray(this.dynamicRoutes[url])) {
            const index = this.dynamicRoutes[url].findIndex((item: any) => item.id === body.id);
            if (index !== -1) {
                this.dynamicRoutes[url][index] = body;
                return body;
            }
        }
        return undefined;
    }

    delete(reqInfo: any) {
        const urlParts = reqInfo.url.split('/');
        const id = urlParts.pop();
        const baseUrl = this.normalizeUrl(urlParts.join('/'));

        if (this.dynamicRoutes[baseUrl] && Array.isArray(this.dynamicRoutes[baseUrl])) {
            const index = this.dynamicRoutes[baseUrl].findIndex((item: any) => item.id === id);
            if (index !== -1) {
                this.dynamicRoutes[baseUrl].splice(index, 1);
                return { message: 'Deleted successfully' };
            }
        }
        return undefined;
    }

    private normalizeUrl(url: string): string {
        return url.replace(/\/+$/, '');
    }

    private generateId(): string {
        return Math.random().toString(36).substr(2, 9);
    }
}
