import { computed, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { UserModel } from '@shared/models/user.model';
import { IUser } from '@shared/interfaces/user.interface';

@Injectable({
    providedIn: 'root',
})
export class UserStoreService {
    private _user: WritableSignal<UserModel> = signal(new UserModel());

    readonly user: Signal<UserModel> = this._user.asReadonly();
    readonly isLoggedIn: Signal<boolean> = computed(() => this.user().isLoggedIn);
    readonly allowLanding: Signal<boolean> = computed(() => this.user().allowLanding);
    readonly admin: Signal<boolean> = computed(() => this.user().role == 'ADMIN');
    readonly technical: Signal<boolean> = computed(() => this.user().role == 'TECHNICAL');
    readonly support: Signal<boolean> = computed(() => this.user().role == 'SUPPORT');
    readonly facilityManager: Signal<boolean> = computed(
        () => this.user().role == 'FACILITY_MANAGER',
    );

    updateUser(partialUser: Partial<IUser>) {
        this._user.update(user => {
            user.update(partialUser);
            // user.role.update({
            //    admin: false,
            //    user: false,
            // })
            return Object.assign(new UserModel(), user);
        });
    }

    clearUser(): void {
        this._user.set(new UserModel());
    }
}
