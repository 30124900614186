import { GenericModel } from '@shared/models/generic.model';
import { IUser, IUserDto } from '@shared/interfaces/user.interface';
import { TUserType } from '@shared/interfaces/user-data-role.interface';

export class UserModel extends GenericModel<IUser, IUserDto> implements IUser {
    countryCode: string;
    email: string;
    firstName: string;
    id: string;
    lastName: string;
    role: TUserType;
    tool: boolean;
    vertical: boolean;
    spectrio: boolean;
    adminCountries: string[];
    propertyAccess: string[];

    isLoggedIn: boolean;
    allowLanding: boolean;
    autoRedirect: boolean;
    profilePicture: string;

    constructor(data?: Partial<IUserDto>) {
        super(data, UserModel);

        this.countryCode = data?.countryCode ?? '';
        this.email = data?.email ?? '';
        this.firstName = data?.firstName ?? '';
        this.id = data?.id ?? '';
        this.lastName = data?.lastName ?? '';
        this.role = data?.role ?? 'USER';
        this.adminCountries = data?.adminCountries ?? [];
        this.propertyAccess = data?.propertyAccess ?? [];
        this.tool = data?.toolAccess ?? false;
        this.vertical = data?.verticalAccess ?? false;
        this.spectrio = data?.spectrioAccess ?? false;

        this.profilePicture = '';
        this.isLoggedIn = false;
        this.allowLanding = false;
        this.autoRedirect = false;
    }

    get hasRole(): boolean {
        return this.tool || this.vertical || this.spectrio;
    }

    dataTransferObject(): Partial<IUserDto> {
        return {
            ...this.getOriginalData(),
            countryCode: this.countryCode,
            email: this.email,
            firstName: this.firstName,
            lastName: this.lastName,
            adminCountries: this.adminCountries,
            propertyAccess: this.propertyAccess,
            role: this.role,
            toolAccess: this.tool,
            verticalAccess: this.vertical,
            spectrioAccess: this.spectrio,
        };
    }
}
