[
    {
        "adminCountries": ["DE"],
        "countryCode": "DE",
        "email": "d.meier.ito+testInfo@gmail.com",
        "firstName": "Test",
        "lastName": "Daniel",
        "roles": []
    },
    {
        "adminCountries": [],
        "countryCode": "FI",
        "email": "dennis.eicker.de+germany@gmail.com",
        "firstName": "",
        "lastName": "",
        "roles": []
    },
    {
        "adminCountries": [],
        "countryCode": "FI",
        "email": "alexander.luecke@it-objects.de",
        "firstName": "",
        "lastName": "",
        "roles": []
    },
    {
        "adminCountries": [],
        "countryCode": "FI",
        "email": "dennis.eicker@hotmail.de",
        "firstName": "",
        "lastName": "",
        "roles": []
    },
    {
        "adminCountries": [],
        "countryCode": "FI",
        "email": "d.meier.ito@gmail.com",
        "firstName": "",
        "lastName": "",
        "roles": []
    },
    {
        "adminCountries": [],
        "countryCode": "FI",
        "email": "petteri.dammert@kone.com",
        "firstName": "Petteri",
        "lastName": "Dammert",
        "roles": []
    },
    {
        "adminCountries": [],
        "countryCode": "FI",
        "email": "ludwig.gottschalk+2@it-objects.de",
        "firstName": "",
        "lastName": "",
        "roles": []
    },
    {
        "adminCountries": [],
        "countryCode": "FI",
        "email": "Ultraraypower+test@yahoo.com",
        "firstName": "",
        "lastName": "",
        "roles": []
    },
    {
        "adminCountries": [],
        "countryCode": "FI",
        "email": "Ludwig.Gottschalk@kone.com",
        "firstName": "Ludwig",
        "lastName": "Gottschalk",
        "roles": []
    },
    {
        "adminCountries": [],
        "countryCode": "FI",
        "email": "Satu.Kuikka@KONE.com",
        "firstName": "Satu",
        "lastName": "Kuikka",
        "roles": []
    },
    {
        "adminCountries": [],
        "countryCode": "FI",
        "email": "dennis.eicker@outlook.de",
        "firstName": "",
        "lastName": "",
        "roles": []
    },
    {
        "adminCountries": [],
        "countryCode": "FI",
        "email": "Raymond.Ogienoyevbede@KONE.com",
        "firstName": "Raymond",
        "lastName": "Ogienoyevbede",
        "roles": []
    },
    {
        "adminCountries": [],
        "countryCode": "FI",
        "email": "test@test.de",
        "firstName": "",
        "lastName": "",
        "roles": []
    },
    {
        "adminCountries": [],
        "countryCode": "FI",
        "email": "hzhang@verticalcity.com",
        "firstName": "",
        "lastName": "",
        "roles": []
    },
    {
        "adminCountries": [],
        "countryCode": "FI",
        "email": "antti.ruhanen@kone.com",
        "firstName": "",
        "lastName": "",
        "roles": []
    },
    {
        "adminCountries": [],
        "countryCode": "FI",
        "email": "dennis.eicker@web.de",
        "firstName": "",
        "lastName": "",
        "roles": []
    },
    {
        "adminCountries": [],
        "countryCode": "FI",
        "email": "SVC_DXSSOLUTIONS@konenet.com",
        "firstName": "",
        "lastName": "SVC_DXSSOLUTIONS",
        "roles": []
    },
    {
        "adminCountries": [],
        "countryCode": "FI",
        "email": "rui.branco@kone.com",
        "firstName": "Rui",
        "lastName": "Branco",
        "roles": []
    },
    {
        "adminCountries": [],
        "countryCode": "FI",
        "email": "ludwig.gottschalk@it-objects.de",
        "firstName": "",
        "lastName": "",
        "roles": []
    },
    {
        "adminCountries": [],
        "countryCode": "FI",
        "email": "dennis.eicker@kone.com",
        "firstName": "Dennis",
        "lastName": "Eicker",
        "roles": []
    },
    {
        "adminCountries": [],
        "countryCode": "FI",
        "email": "dennis.eicker@it-objects.de",
        "firstName": "",
        "lastName": "",
        "roles": []
    },
    {
        "adminCountries": [],
        "countryCode": "FI",
        "email": "ludwig.gottschalk@gmail.com",
        "firstName": "",
        "lastName": "",
        "roles": []
    },
    {
        "adminCountries": [],
        "countryCode": "FI",
        "email": "d.meier.ito+testInfo@gmail.com",
        "firstName": "",
        "lastName": "",
        "roles": []
    },
    {
        "adminCountries": [],
        "countryCode": "FI",
        "email": "mediascreen.test@kone.com",
        "firstName": "",
        "lastName": "",
        "roles": []
    },
    {
        "adminCountries": [],
        "countryCode": "FI",
        "email": "Widura.Schwittek@KONE.com",
        "firstName": "Widura",
        "lastName": "Schwittek",
        "roles": []
    },
    {
        "adminCountries": [],
        "countryCode": "FI",
        "email": "widura.schwittek@it-objects.de",
        "firstName": "",
        "lastName": "",
        "roles": []
    },
    {
        "adminCountries": [],
        "countryCode": "FI",
        "email": "kaushik.katariya@it-objects.de",
        "firstName": "",
        "lastName": "",
        "roles": []
    },
    {
        "adminCountries": [],
        "countryCode": "FI",
        "email": "vikram.nimkar@kone.com",
        "firstName": "Vikram",
        "lastName": "Nimkar",
        "roles": []
    },
    {
        "adminCountries": [],
        "countryCode": "FI",
        "email": "daniel.meier@kone.com",
        "firstName": "Daniel",
        "lastName": "Meier",
        "roles": []
    },
    {
        "adminCountries": [],
        "countryCode": "FI",
        "email": "philip.korte@it-objects.de",
        "firstName": "",
        "lastName": "",
        "roles": []
    },
    {
        "adminCountries": [],
        "countryCode": "FI",
        "email": "dm@it-objects.de",
        "firstName": "",
        "lastName": "",
        "roles": []
    },
    {
        "adminCountries": [],
        "countryCode": "FI",
        "email": "rui.branco@it-objects.pt",
        "firstName": "",
        "lastName": "",
        "roles": []
    }
]
