import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { IUserDto } from '@shared/interfaces/user.interface';
import { UserModel } from '@shared/models/user.model';
import { catchError, map, Observable, of } from 'rxjs';
import { IResponseItem } from '@shared/interfaces/response-item.interface';
import { translations } from '@shared/utils/translations';
import { tools } from '@shared/utils/tools';

const ENDPOINT = environment.api + '/users/';

@Injectable({
    providedIn: 'root',
})
export class UsersHttpService {
    private http: HttpClient = inject(HttpClient);

    get() {
        return this.http
            .get<IUserDto[]>(ENDPOINT)
            .pipe(map(users => users.map(p => new UserModel(p))));
    }

    getById(id?: string) {
        return this.http.get<IUserDto>(ENDPOINT + id).pipe(map(user => new UserModel(user)));
    }

    create(user: UserModel): Observable<IResponseItem<UserModel>> {
        return this.http.post<IUserDto>(ENDPOINT, user.dataTransferObject()).pipe(
            map(user => ({
                value: new UserModel(user),
            })),
            catchError(error => {
                return of({
                    error: {
                        error: true,
                        message: tools.getErrorTranslation(error, translations.users.error_add),
                    },
                });
            }),
        );
    }

    update(user: UserModel): Observable<IResponseItem<UserModel>> {
        return this.http.put<IUserDto>(ENDPOINT + user.id, user.dataTransferObject()).pipe(
            map(user => ({
                value: new UserModel(user),
            })),
            catchError(error => {
                return of({
                    error: {
                        error: true,
                        message: tools.getErrorTranslation(error, translations.users.error_update),
                    },
                });
            }),
        );
    }

    delete(id: string): Observable<IResponseItem<UserModel>> {
        return this.http.delete(ENDPOINT + id).pipe(
            map(() => ({
                success: true,
            })),
            catchError(error =>
                of({
                    error: {
                        error: true,
                        message: tools.getErrorTranslation(error, translations.users.error_delete),
                    },
                }),
            ),
        );
    }

    getUserData(): Observable<IResponseItem<UserModel>> {
        // const errorResponse = {
        //    status: 403,
        //    message: 'Unauthorized'
        // };
        //
        // return throwError(errorResponse);
        return this.http.get<IUserDto>(ENDPOINT + 'me').pipe(
            map(user => ({
                value: new UserModel(user),
            })),
            catchError(error => {
                return of({
                    error: {
                        error: true,
                        message: tools.getErrorTranslation(error, translations.users.error_update),
                    },
                });
            }),
        );
    }
}
